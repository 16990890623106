$(function () {
	// global
	APP.init();
	// Init Lib
	lozadInit();
	fancyboxInit();
	countUpInit();
	// elm
	nextSection();
	accordionInit();
	loadItemInit();

	shareSocialPopup();
});

$(window).on('scroll', function () {
	APP.fixed();
});

// variable
var header = $("header"),
	body = $("body"),
	backdrop = $(".backdrop"),
	backdropToggle = $(".toggle-backdrop"),
	backToTop = $(".back-to-top"),
	buttonMenu = $("#buttonMenu"),
	buttonSearch = $(".button-search"),
	mobileWrap = $(".mobile-wrap"),
	searchWrap = $(".search-wrap"),
	heightHeader = $("header").height(),
	heightWindow = $(window).height(),
	widthWindow = $(window).width(),
	outerHeightWindow = $(window).outerHeight();

// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		// header
		$(window).scrollTop() > heightHeader
			? header.addClass("active")
			: header.removeClass("active");
		$(window).scrollTop() > (outerHeightWindow - heightHeader)
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backdrop: () => {
		backdrop.on('click', function () {
			$(this).removeClass('open');
			backdropToggle.removeClass('open');
			body.removeClass('overflow-hidden');
		})
	},
	backToTop: () => {
		backToTop.on('click', function () {
			$('html, body').animate({ scrollTop: 0 }, 500);
		})
	},
	mapping: () => {
		const mainMenu = $("header .navbar-nav").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "prependTo",
			desktopWrapper: "header .header-bot",
			desktopMethod: "prependTo",
			breakpoint: 1279.98,
		});
		const language = $("header .language").mapping({
			mobileWrapper: "header .header-bot",
			mobileMethod: "prependTo",
			desktopWrapper: "header .header-line-ver",
			desktopMethod: "insertAfter",
			breakpoint: 1279.98,
		});
		const fanpage = $("header .button-fanpage").mapping({
			mobileWrapper: ".navbar-nav-list",
			mobileMethod: "insertAfter",
			desktopWrapper: "header .header-top",
			desktopMethod: "prependTo",
			breakpoint: 1279.98,
		});
		const search = $("header .searchbox").mapping({
			mobileWrapper: ".search-wrap",
			mobileMethod: "appendTo",
			desktopWrapper: "header .header-top",
			desktopMethod: "appendTo",
			breakpoint: 1279.98,
		});
	},
	toggleDropdown: () => {
		const dropdown = $(".dropdown"),
			dropdownToggle = $(".dropdown-toggle"),
			dropdownMenu = $(".dropdown-menu");
		dropdownToggle.on("click", function () {
			$(this).toggleClass("active");
			$(this).parents(dropdown).find(dropdownMenu).toggleClass("open");
		});
	},
	toggleElm: (button, elmToggle) => {
		button.on("click", function () {
			$(this).toggleClass("open");
			elmToggle.toggleClass('open');
			backdrop.toggleClass('open');
			body.toggleClass('overflow-hidden');
		});
	},
	init: () => {
		APP.backdrop();
		APP.backToTop();
		APP.mapping();
		APP.toggleElm(buttonMenu, mobileWrap);
		APP.toggleElm(buttonSearch, searchWrap);
	}
}

const defaultSwiper = new Swiper(".default .swiper", {
	// spaceBetween: 12,
	// observer: true,
	// observeParents: true,
	// lazy: {
	// 	loadPrevNext: true,
	// },
	// navigation: {
	// 	nextEl: ".default .button-next",
	// 	prevEl: ".default .button-prev",
	// },
	// pagination: {
	// 	el: ".default .swiper-pagination",
	// 	clickable: true,
	// },
	// breakpoints: {
	// 	768: {
	// 		slidesPerView: 2,
	// 	},
	// 	1024: {
	// 		slidesPerView: 3,
	// 	},
	// 	1280: {
	// 		slidesPerView: 4,
	// 	},
	// },
});

const homeBanner = new Swiper(".home-banner .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 0,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-banner .button-next",
		prevEl: ".home-banner .button-prev",
	},
	pagination: {
		el: ".home-banner .swiper-pagination",
		clickable: true,
	},
});

const homeProductSlider = new Swiper(".home-2 .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 30,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-2 .button-next",
		prevEl: ".home-2 .button-prev",
	},
	pagination: {
		el: ".home-2 .swiper-pagination",
		clickable: true,
	},
});

const homeBrandSlider = new Swiper(".home-5 .swiper", {
	// loop: true,
	// autoplay: {
	// 	delay: 3000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	speed: 1000,
	spaceBetween: 10,
	slidesPerView: 3,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-5 .button-next",
		prevEl: ".home-5 .button-prev",
	},
	pagination: {
		el: ".home-5 .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 20,
		},
		1280: {
			slidesPerView: 5,
			spaceBetween: 30,
		}
	}
});

const menuThumbsSlider = new Swiper(".product-detail-top .swiper-thumbs", {
	loop: true,
	speed: 1000,
	slidesPerView: 4,
	spaceBetween: 10,
	observer: true,
	observeParents: true,
	watchSlidesProgress: true,
	lazy: {
		loadPrevNext: true,
	},
	breakpoints: {
		1280: {
			slidesPerView: 4,
			spaceBetween: 20,
		},
	},
});

const menuTopSlider = new Swiper(".product-detail-top .swiper-top", {
	loop: true,
	speed: 1000,
	spaceBetween: 10,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".product-detail-top .button-next",
		prevEl: ".product-detail-top .button-prev",
	},
	thumbs: {
		swiper: menuThumbsSlider,
	},
});

const menuRelativeSlider = new Swiper(".product-detail-bot .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 10,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".product-detail-bot .button-next",
		prevEl: ".product-detail-bot .button-prev",
	},
	pagination: {
		el: ".product-detail-bot .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 20,
		},
		1280: {
			slidesPerView: 4,
			spaceBetween: 30,
		}
	}
});

function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad('.lozad', {
		threshold: 0.1,
		enableAutoReload: true,
	});
	// data-background-image="image.png"
	const backgroundObserver = lozad('.lozad-bg', {
		threshold: 0.1,
	});
	observer.observe();
	backgroundObserver.observe();
}

function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	$(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}

function countUpInit(){
	$('.count-up').each(function(index){
		$(this).attr('id', `countUp-${index}`)
		const endVal = $(this).data('count');

		const options = {
			separator: '.',
			enableScrollSpy: true,
			scrollSpyDelay: 1000,
			scrollSpyOnce: true,
		};

		const countUp = new CountUp(`countUp-${ index }`, endVal, options);

		if (!countUp.error) {
			countUp.start();
		} else {
			console.error(countUp.error);
		}
	})
}

function accordionInit() {
	$('.accordion-item').on('click', function () {
		$(this)
			.toggleClass('active')
			.siblings().removeClass('active')
		$(this)
			.find('.accordion-header').toggleClass('active')
			.parents('.accordion-item')
			.siblings().find('.accordion-header').removeClass('active')
		$(this)
			.find('.accordion-content').slideToggle()
			.parents('.accordion-item')
			.siblings().find('.accordion-content').slideUp('active')
	});
}

function nextSection() {
	const heightHeader = $("header").outerHeight();
	$(".next-section").on("click", function () {
		$("html, body").animate({
			scrollTop: $(this).closest("section").next().offset().top - heightHeader,
		}, 500);
	});
}

function loadItemInit(){
	$('.load-item-init').each(function(index){
		let showItem = $(this).data('show');
		let itemEml = $(this).find('.load-item');
		let btnLoad = $(this).find('.btn-load-more');
		if (itemEml.length <= showItem){
			btnLoad.fadeOut('slow');
		}
		itemEml.slice(0, showItem).show();
		btnLoad.on('click', function (e) {
			e.preventDefault();
			let itemEmlHide = $(this).parents('.load-item-init').find('.load-item:hidden');
			itemEmlHide.slice(0, showItem).slideDown();
			let countItemEmlHide = $(this).parents('.load-item-init').find('.load-item:hidden');
			if (countItemEmlHide.length <= 0) {
				btnLoad.fadeOut('slow');
			}
		});
	})
}

function shareSocialPopup(){
	$('.share-popup').on('click', function (e) {
		e.preventDefault();
		window.open($(this).attr("href"), "popupWindow", "width=500,height=500,scrollbars=yes,resizable=yes,left=100,top=100");
	});
}
